import React from 'react'
import { useNavigate } from 'react-router-dom'
import { HER_HUB_PATH } from 'views/HerHub/utils/constants'

function PostClassThankYou() : JSX.Element {
  const navigate = useNavigate()

  return (
    <div className="py-4 px-4 flex flex-col w-full items-center justify-center max-w-2xl mx-auto space-y-3">
      <div className="flex flex-col gap-10">
        <h1 className="hub-heading text-center text-3xl">{`Post Class Survey`}</h1>
      </div>
      <p className="text-center text-lg">{`Thank you for taking the time to fill this out!  ~Aeroflow Breastpumps x Lactation Link`}</p>
      <p
        className="text-lg my-10 a"
        onClick={() => { navigate( HER_HUB_PATH.concat( window.location.search ) ) }}
      >
        {`Go back to my Account`}
      </p>
    </div>
  )
}

export default PostClassThankYou