import React, { useEffect, useState } from 'react'
import PageNavigator from 'components/pageNavigator'
import { BUNDLE_CONFIRM_PATH, BUNDLE_THANK_YOU_PATH, SCHEDULE_BUNDLE_BASE_PATH } from './utils/constants'
import { useNavigate } from 'react-router-dom'
import { ScheduleClass } from 'views/ScheduleClass'
import useSelectedBundle from 'hooks/useSelectedBundle'
import { AppointmentsLoadingBox } from 'components/AppointmentsLoadingBox'
import { ThemedModal } from 'components/ThemedModal'
import { Class, TimeSlot } from 'types/shopScheduleTypes'
import { SchedulingErrorBlock } from 'components/SchedulingErrorBlock'
import { useGetBundleDetails } from './hooks/useGetBundleDetails'
import { submitPartialScheduling } from 'views/ScheduleClass/utils/utils'
import usePatientDetails from 'hooks/usePatientDetails'
import { Confirm } from 'views/Confirm'
import { ShopScheduleThankYou } from 'views/ThankYou'
import { BUNDLE_SELECTION_BASE_PATH } from 'routes'
import useSeriesReference from 'hooks/useSeriesReference'
import useAuthStore from 'stores/auth'
import useEligibleAppointments from 'hooks/useEligibleAppointments'

function ScheduleBundle() : JSX.Element {

  const [ pageNames, setPageNames ] = useState<string[]>( [] )
  const [ modalMessageStatus, setModalMessageStatus ] = useState<boolean>( true )
  const [ selectionError, setSelectionError ] = useState<string>( `` )

  const navigate = useNavigate()
  const { selectedBundle } = useSelectedBundle()
  const { seriesReference, setSeriesReference } = useSeriesReference()
  const { buildAuthorizer } = useAuthStore()
  const authHeader = buildAuthorizer()

  const { timezone, setTimezone } = usePatientDetails()
  const { bundleDetails, error, loading, selectTime, removeTimes, excludeDays, toggleSkipScheduling } = useGetBundleDetails( timezone )
  const { excludeDays: prevScheduledDays } = useEligibleAppointments( timezone )

  useEffect( () => {
    if ( !selectedBundle || !selectedBundle?.classes?.length ) navigate( BUNDLE_SELECTION_BASE_PATH.concat( window.location.search ) )
  }, [] )

  useEffect( ( ) => {
    if ( bundleDetails?.classes?.length ) {
      let firstUnscheduled:number|null = null
      const pages = bundleDetails.classes.map( ( _class, i ) => {
        if ( !_class.selected_timeslot && !firstUnscheduled && !_class.skip_scheduling ) firstUnscheduled = i + 1

        return SCHEDULE_BUNDLE_BASE_PATH.concat( `/bundle-${i+1}` )
      })
      pages.push( BUNDLE_CONFIRM_PATH, BUNDLE_THANK_YOU_PATH )
      setPageNames( pages )
    }
  }, [ bundleDetails ] )

  useEffect( () => {
    if ( bundleDetails?.classes?.length ) {
      let firstUnscheduled:number|null = null
      bundleDetails.classes.forEach( ( classItem: Class, i: number ) => {
        if ( !classItem.selected_timeslot && !firstUnscheduled && !classItem.skip_scheduling ) firstUnscheduled = i + 1
      })

      return firstUnscheduled ? navigate( `${SCHEDULE_BUNDLE_BASE_PATH}/bundle-${firstUnscheduled}${window.location.search}` ) : navigate( `${BUNDLE_CONFIRM_PATH}${window.location.search}` )
    }
  }, [ bundleDetails ] )

  const handlePartialSchedule = ( selectedSlot: TimeSlot, classInfo: Class ) => {
    const status = selectTime( selectedSlot, classInfo )
    if ( status ) {
      submitPartialScheduling( classInfo.class_sku, selectedSlot, authHeader, selectedBundle?.bundle_sku )
      if ( classInfo.is_series ) setSeriesReference( classInfo.class_series_pk, classInfo.class_sequence, selectedSlot.timeslot_exact_timestamp )
    } else setSelectionError( `Oops... we encountered an exception attempting to select this timeslot.` )
  }

  if ( error || selectionError ) return <SchedulingErrorBlock message={error || selectionError} />

  if ( !pageNames.length || loading || !timezone || !prevScheduledDays ) return <AppointmentsLoadingBox message={`Please hold tight while we grab the available class dates and times...`} />

  return (
    <div className="flex flex-col relative justify-center items-center overflow-hidden px-3">
      <ThemedModal
        message="Hey Mama! Time to schedule your classes. Select from the available dates and times using the calendar below."
        open={modalMessageStatus}
        handleClose={() => { setModalMessageStatus( false ) }}
      />
      <PageNavigator
        pageNames={pageNames}
        childSharedProps={{
          totalSteps: selectedBundle?.classes?.length
        }}
        pageClassName="w-full"
      >
        {
          bundleDetails?.classes?.length &&
          bundleDetails.classes.map( ( classInfo, i ) => {
            return (
              <ScheduleClass
                key={classInfo.class_id}
                step={i+1}
                excludeDays={new Set( [ ...Array.from( excludeDays ), ...Array.from( prevScheduledDays || [] ) ] ) as Set<string>}
                totalSteps={bundleDetails?.classes?.length}
                classInfo={classInfo}
                bundleDetails={bundleDetails}
                handlePartialSchedule={( selectedSlot: TimeSlot ) => { handlePartialSchedule( selectedSlot, classInfo ) }}
                timezone={timezone}
                setTimezone={setTimezone}
                seriesReference={seriesReference}
                removeTimes={removeTimes}
                toggleSkipScheduling={toggleSkipScheduling}
              />
            )
          })
        }
        <Confirm
          classes={bundleDetails?.classes ?? []}
          removeTimes={removeTimes}
          toggleSkipScheduling={toggleSkipScheduling}
        />
        <ShopScheduleThankYou />
      </PageNavigator>
    </div>
  )
}

export default ScheduleBundle