import Tile from '../Tile'
import 'styles/one_on_one.css' // TODO maybe move this out once this becomes a child
import { schedule_one_on_one } from 'assets/herHub'
import useScheduledAppointments from 'hooks/useScheduledAppointments'
import RingLoader from 'components/RingLoader'
import { SchedulingErrorBlock } from 'components/SchedulingErrorBlock'
import { ScheduleAppointment } from 'types/shopScheduleTypes/ScheduleAppointment'
import AppointmentBlock from './components/AppointmentBlock'
import useHerHubStore from 'stores/herHub'
import { isDayAway, isSameDay, timeFromNowPrettyString } from '../YourNextClass/time'
import JoinNowHeader from '../YourNextClass/JoinNowCountdown'
import { INTEGRATED_PATIENT_HISTORY_FEATURE_FLAG } from 'routes'

// Note: this component is currently not in-use, but there are plans to finish and implement it in a future iteration
export default function Management() : JSX.Element | null {

  const { upcoming, loading, error, hasSignedQuestionnaire } = useScheduledAppointments()
  const { recentAppointmentReschedule, recentAppointmentCancel } = useHerHubStore()

  if ( error ) return <SchedulingErrorBlock message="Oops... We encountered an error attempting to grab your upcoming appointments. Please reload the page and try again." />

  // check if any recently rescheduled or cancelled appointments have not been updated on the backend yet
  // once the rescheduled and cancelled appointments are updated they are removed from these arrays
  // empty arrays signify that everything is up to date
  if ( loading || recentAppointmentReschedule.length || recentAppointmentCancel.length ) return <div className="my-4"><RingLoader /></div>

  if ( !upcoming.length && !recentAppointmentReschedule.length ) return null

  const nextAppointment = upcoming?.length ? upcoming[0] : recentAppointmentReschedule?.length ? recentAppointmentReschedule[0] : null
  const nextApptTime = nextAppointment?.start_time_local ? nextAppointment?.start_time_local.substring( 0, 19 ) : ( nextAppointment as any ).timeslot_exact_timestamp.substring( 0, 19 )

  return (
    <Tile
      headerContent={
        <div>
          {
            nextAppointment && (
              isSameDay( nextApptTime ) ?
                <JoinNowHeader
                  time={nextAppointment?.start_time_local ? nextAppointment?.start_time_local.substring( 0, 19 ) : ( nextAppointment as any ).timeslot_exact_timestamp.substring( 0, 19 )}
                  zoom_link={( nextAppointment as any )?.interaction_details ?? ``}
                  event_instance_id={( nextAppointment as any )?.event_instance_id ?? ``}
                  isAppointment
                />
                :
                <p className="font-semibold">{`Your next appointment:`} {`${timeFromNowPrettyString( upcoming[0]?.start_time_local ? upcoming[0].start_time_local : ( upcoming[0] as any ).timeslot_exact_timestamp )}`}</p>
            )
          }

        </div>
      }
    >
      <div className="flex items-center gap-6">
        <img src={schedule_one_on_one} width={70} height={50} />
        <h1 className="prata-heading text-left sm:text-base md:text-lg">{`Appointment Management`}</h1>
      </div>
      {
        !hasSignedQuestionnaire &&
        <div className="mx-auto text-center">
          <p className="my-4">{`Before your appointment, complete the`}</p>
          <button
            className="primary-button mx-auto px-10"
            onClick={() => {
              const googleFormUrl = `https://docs.google.com/forms/d/14ZO3F3kZVbo6BJLxuj7Q-TbpAsKVyR_DsjnYgiVo6IY/viewform?ts=64e0d268&edit_requested=true`
              const historyFormUrl = INTEGRATED_PATIENT_HISTORY_FEATURE_FLAG ? `/patient-history?${window.location.search}` : googleFormUrl
              window.open( historyFormUrl, `_blank` )
            }}
          >
            {`PATIENT HISTORY FORM`}
          </button>
        </div>
      }
      <p className="text-center my-4 text-lg">{`Your Upcoming Appointment`}</p>
      <div className="flex flex-col items-center justify-center gap-5 mb-5">
        {
          upcoming?.length > 0 &&
          <>
            {
              upcoming.map( ( appointment: ScheduleAppointment, i: number ) => {
                return (
                  <AppointmentBlock
                    key={appointment.id}
                    appointment={appointment}
                    hideActions={i === 0 && isDayAway( nextApptTime )}
                  />
                )
              })
            }
          </>

        }
        {/* <p className="text-center font-semibold text-sm px-1 mt-2">{`Appointments may be canceled up to 24 hours beforehand. No-shows or cancellations less than 24 hours before the appointment time will be charged a cancellation fee of $25.00.`}</p> */}
      </div>
    </Tile>
  )
}