import { ScheduleAppointment } from 'types/shopScheduleTypes/ScheduleAppointment'
import { useNavigate } from 'react-router-dom'
import { RESCHEDULE_ONE_ON_ONE_APPOINTMENT } from 'routes'
import CancelAppointment from '../../../../views/CancelAppointment/CancelAppointment'
import { useState } from 'react'
import { AddToCalendarButton } from 'add-to-calendar-button-react'
import tailwindColors from 'config/tailwindColors'
import { getTimeString } from 'utils/time'
import { getAppointmentCalendarEventDescription } from 'utils'
import { isSameDay } from 'components/HubLandingTiles/YourNextClass/time'
import { CustomTooltip } from 'components/HubLandingTiles/CustomTooltip'

function AppointmentBlock({
  appointment,
  hideActions = false
}: {
  appointment: ScheduleAppointment
  hideActions?: boolean
}): JSX.Element {
  const [ isModalOpen, setIsModalOpen ] = useState( false )

  const navigate = useNavigate()

  const time = new Date( appointment?.start_time_local ?? `` )
  const endTime = new Date(
    time.getTime() + appointment.duration * 60 * 1000
  )
  const appointmentDate = appointment?.start_time_local?.substring(
    0,
    10
  )

  const apptSameDay = isSameDay( appointmentDate )

  const handleLaunchZoom = () => {
    window.open(
      appointment.interaction_details,
      `_blank`,
      `noreferrer`
    )
  }

  const hideAddToCalendar = ( id: string ) => {
    const addToCalendarText = document.getElementById(
      `add-to-calendar-appointment-text-${id}`
    )
    addToCalendarText?.classList.add( `hidden` )
  }

  const displayAddToCalendar = ( id: string ) => {
    const addToCalendarText = document.getElementById(
      `add-to-calendar-appointment-text-${id}`
    )
    addToCalendarText?.classList.remove( `hidden` )
  }

  const styleOverrides = `--btn-background: ${tailwindColors.pink[`3`]}; --btn-border: ${tailwindColors.pink[`3`]}; --date-btn-cal-background: ${tailwindColors.pink[`3`]}; --btn-hover-text: ${tailwindColors.gray.dark}; --date-btn-text: ${tailwindColors.gray.dark};`

  return (
    <div>
      <div className="flex gap-5">
        <div className="h-50px w-50px bg-gray-white flex items-center justify-center">
          <img
            src={appointment?.staff_image ?? appointment.image_url}
            className="object-contain"
          />
        </div>
        <div className="flex flex-col">
          <div
            className="relative"
            onMouseEnter={() => {
              hideAddToCalendar( appointment.id )
            }}
            onMouseOut={() => {
              displayAddToCalendar( appointment.id )
            }}
          >
            <AddToCalendarButton
              name={`${appointment.staff_display_name}: Lactation Visit (${appointment.client_first_name} ${appointment.client_last_name})`}
              size="3"
              description={getAppointmentCalendarEventDescription(
                appointment
              )}
              iCalFileName="lactation-link-appointment-calendar-event-download"
              buttonStyle="date"
              styleLight={styleOverrides}
              styleDark={styleOverrides}
              options={[
                `Apple`,
                `Google`,
                `Outlook.com`,
                `Microsoft365`,
                `Yahoo`
              ]}
              location={appointment.interaction_details ? `Join at: ${appointment.interaction_details}` : ``}
              startDate={appointmentDate}
              endDate={appointmentDate}
              startTime={appointment?.start_time_local?.substring(
                11,
                16
              )}
              endTime={getTimeString( endTime )}
              timeZone="currentBrowser"
              hideBranding
              hideCheckmark
              customCss="/resources/css/atcb-override.css"
            />
            <p
              id={`add-to-calendar-appointment-text-${appointment.id}`}
              className="a absolute left-16 bottom-1 text-xs z-40 pointer-events-none"
            >{`Add to Calendar`}</p>
          </div>
          {appointment?.id && (
            <div className="flex mt-4 gap-3 md:gap-6 flex-wrap items-start justify-start">
              <CustomTooltip title={apptSameDay ? `` : `Hey Mama, this appointment has not started just yet. Come back here to join when your appointment is coming up.`} arrow enterTouchDelay={0}>

                <div
                  onClick={apptSameDay ? handleLaunchZoom : undefined}
                  className={`text-sm text-center ${apptSameDay ? `a` : `text-gray-light decoration-gray-light underline font-semibold cursor-default`}`}
                >
                  {`Join Appointment`}
                </div>
              </CustomTooltip>
              {!hideActions && (
                <>
                  <p
                    className="a text-sm text-center"
                    onClick={() => {
                      navigate(
                        RESCHEDULE_ONE_ON_ONE_APPOINTMENT.concat(
                          `${window.location.search}${
                            window.location.search ? `&` : `?`
                          }appointment_id=${appointment.id}`
                        )
                      )
                    }}
                  >
                    {`Reschedule`}
                  </p>
                  <p
                    className="a text-sm text-center"
                    onClick={() => setIsModalOpen( true )}
                  >
                    {`Cancel`}
                  </p>
                  {isModalOpen && (
                    <CancelAppointment
                      handleClose={() => setIsModalOpen( false )}
                      isOpen={isModalOpen}
                      appointment={appointment}
                    />
                  )}
                </>
              )}
            </div>
          )}
        </div>
      </div>
    </div>
  )
}

export default AppointmentBlock
