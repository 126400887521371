import styled from '@emotion/styled'
// the TooltipProps import is there but vs code is throwing an error for some reason
// eslint-disable-next-line import/named
import Tooltip, { tooltipClasses, TooltipProps } from '@mui/material/Tooltip'
import tailwindColors from 'config/tailwindColors'

const CustomTooltip = styled( ({ className, ...props }: TooltipProps ) => (
  <Tooltip
    {...props} classes={{
      popper: className
    }}
  />
) )( () => ({
  [`& .${tooltipClasses.arrow}`]: {
    color: tailwindColors.pink[`2`]
  },
  [`& .${tooltipClasses.tooltip}`]: {
    backgroundColor: tailwindColors.pink[`2`],
    color: tailwindColors.gray.dark,
    fontSize: 13
  }
}) )

export default CustomTooltip