import { FormEvent, ChangeEvent, useState, createRef } from "react"
import useForgotPasswordStore, { Validate } from "./store"
import useAuthStore from "stores/auth"
import { TextInput, CheckboxInput } from "components/Forms/components"
import { reportToSentry } from "utils/reportToSentry"
import { useMutation } from "@apollo/client"
import { resetPasswordMutation, generateCustomerTokenMutation } from "graphql/mutations"
import RingLoader from "components/RingLoader"
import { useNavigate } from "react-router-dom"

export default function ResetPasswordForm() : JSX.Element {

  const { email, newPassword, newPasswordConfirmation, setFieldState, getToken } = useForgotPasswordStore()
  const { setCurrentToken } = useAuthStore()

  const [ shouldHideErrors, setShouldHideErrors ] = useState( true )
  const [ errorMessages, setErrorMessages ] = useState<Record<string, string>>({
    email: ``,
    newPassword: ``,
    newPasswordConfirmation: ``
  })
  const [ submitErrorMessage, setSubmitErrorMessage ] = useState( `` )

  const inputRefs = {
    email: createRef<HTMLInputElement>(),
    newPassword: createRef<HTMLInputElement>(),
    newPasswordConfirmation: createRef<HTMLInputElement>()
  } as Record<string, React.RefObject<HTMLInputElement>>

  const [ showPassword, setShowPassword ] = useState( false )

  const navigate = useNavigate()

  const [ resetPassword, { loading: loadingResetPassword}] = useMutation( resetPasswordMutation, {
    variables: {
      resetPasswordToken: getToken(),
      email,
      newPassword
    },
    onError: ( ( error ) => {
      reportToSentry( new Error ( `reset password mutation on -> PasswordResetForm`, {
        cause: error
      }) )
    })
  })

  const [ login, { loading: loadingLogin }] = useMutation( generateCustomerTokenMutation, {
    variables: {
      email,
      password: newPassword
    },
    onError: ( ( error ) => {
      reportToSentry( new Error ( `login mutation on -> PasswordResetForm`, {
        cause: error
      }) )
    })
  })

  const setErrorMessage = ( name : string, message : string ) => {
    setErrorMessages({
      ...errorMessages,
      [name]: message
    })
  }

  const handleSubmit = async ( e : FormEvent ) => {
    e.preventDefault()
    setShouldHideErrors( false )
    // Check for errors and autofocus first error
    const erroredInput = Object.keys( errorMessages ).find( ( name ) => ( errorMessages[name] !== `` ) )
    if ( erroredInput ) {
      setSubmitErrorMessage( `Please check the inputs and try again.` )

      return inputRefs[erroredInput].current?.focus()
    }
    // submit reset password using getToken
    const result = await resetPassword()

    if ( result?.errors?.length ) {
      return setSubmitErrorMessage( result.errors.map( errorObject => { return errorObject.message }).join( ` ` ) )
    }

    if ( !result?.data?.resetPassword ) {
      return setSubmitErrorMessage( `We are unable to reset your password.` )
    }

    const loginResult = await login()
    if ( loginResult?.data?.generateCustomerToken?.token ) {
      setCurrentToken( loginResult.data.generateCustomerToken.token )

      return navigate( `/my-account` )
    }

    setSubmitErrorMessage( `We are unable to log you in.` )
    reportToSentry( new Error( `Unable to log in after password reset` ), {
      loginResult
    })
  }

  const sharedInputProps = {
    errorMessageClassName: `text-error px-3 text-center`,
    errorClassName: `input max-w-lg mx-auto`,
    labelClassName: `max-w-lg mx-auto`,
    onChange: ( e : ChangeEvent<HTMLInputElement> ) => {
      setShouldHideErrors( true )
      setSubmitErrorMessage( `` )
      const { name, value } = e.target
      // If password input, alt value is the confirm password and visa versa
      const altValuePassword = ( name === `newPassword` ) ? newPasswordConfirmation : ( name === `newPasswordConfirmation` ) ? newPassword : ``
      const _errormessage = Validate( name, value, altValuePassword )
      setErrorMessage( name, _errormessage )
      setFieldState( e.target.name, e.target.value )
    }
  }

  const heading = <h1 className="heading">{`Reset Password`}</h1>


  if ( loadingResetPassword || loadingLogin ) {
    return (
      <div className="flex flex-col gap-4 my-4">
        {heading}
        <RingLoader />
        {loadingResetPassword && <p className="text-center">{`Resetting password...`}</p>}
        {loadingLogin && <p className="text-center">{`Logging in...`}</p>}
      </div> )
  }

  return (
    <form className="max-w-md mx-auto flex flex-col items-center text-center gap-4 my-4" onSubmit={handleSubmit}>

      {heading}

      <TextInput
        name="email"
        value={email}
        label={`Confirm your email`}
        reference={inputRefs.email}
        errorMessage={!shouldHideErrors ? errorMessages.email : ``}
        {...sharedInputProps}
      />

      <TextInput
        name="newPassword"
        type={showPassword ? `text` : `password`}
        value={newPassword}
        label={`Enter your new password`}
        reference={inputRefs.newPassword}
        errorMessage={!shouldHideErrors ? errorMessages.newPassword : ``}
        {...sharedInputProps}
      />

      <TextInput
        name="newPasswordConfirmation"
        type={showPassword ? `text` : `password`}
        value={newPasswordConfirmation}
        label={`Confirm new password`}
        reference={inputRefs.newPasswordConfirmation}
        errorMessage={!shouldHideErrors ? errorMessages.newPasswordConfirmation : ``}
        {...sharedInputProps}
      />

      <CheckboxInput
        containerClassName="flex items-center justify-center mt-4"
        className="w-6"
        name="showPasswords"
        label={`Show Passwords`}
        value={showPassword}
        onChange={() => setShowPassword( !showPassword )}
      />

      { submitErrorMessage && !shouldHideErrors && <p className="text-error max-w-md mx-auto text-center">{submitErrorMessage}</p>}

      <button className="primary-button primary-button my-4" type="submit" disabled={!shouldHideErrors}>{`Reset Password`}</button>

    </form>
  )
}