import { defaultOTPLoginError, defaultOTPSendError, loginOneTimePassword, sendOneTimePassword } from 'apis'
import { TextInput, Validators } from 'components/Forms/components'
import RingLoader from 'components/RingLoader'
import { useState } from 'react'
import { useNavigate, useLocation } from 'react-router-dom'
import useAuthStore from 'stores/auth'
import { formatPhoneNumber } from 'utils'
import { reportToSentry } from 'utils/reportToSentry'

function OneTimePasswordForm() : JSX.Element {
  const [ phone, setPhone ] = useState( `` )
  const [ oneTime, setOneTime ] = useState( `` )
  const [ sendLoading, setSendLoading ] = useState( false )
  const [ submitLoading, setSubmitLoading ] = useState( false )

  const [ sendError, setSendError ] = useState( `` )
  const [ submitError, setSubmitError ] = useState( `` )

  const [ sentOneTimePassword, setSentOneTimePassword ] = useState( false )

  const { setCurrentToken } = useAuthStore()
  const navigate = useNavigate()
  const location = useLocation()


  async function handleOneTimePasswordSend() {
    setSendLoading( true )
    const responseJson = await sendOneTimePassword( phone )
    const response = JSON.parse( responseJson )
    setSendLoading( false )
    if ( !response || response?.success !== `true` ) {
      reportToSentry( new Error( `OTP Login: Error sending OTP to patient` ), {
        phone,
        response
      })

      return setSendError( defaultOTPSendError )
    }
    setSentOneTimePassword( true )
  }

  async function handleOneTimePasswordSignIn() {

    setSubmitLoading( true )

    const responseJson = await loginOneTimePassword( phone, oneTime )
    const response = JSON.parse( responseJson )

    setSubmitLoading( false )

    if ( !response || response?.success !== `true` || !response?.token ) {
      reportToSentry( new Error( `OTP Login: Error logging in patient with OTP` ), {
        phone,
        oneTime,
        response
      })

      return setSubmitError( defaultOTPLoginError )
    }

    setCurrentToken( response.token )
    navigate( location?.state?.from ?? `/my-account`, {
      replace: true
    })
  }

  return (
    <div className="max-w-sm w-full mx-auto flex flex-col items-center gap-10">
      {
        !sentOneTimePassword ? (
          <div className="flex flex-col items-center justify-center gap-5 w-full">
            {
              sendLoading ? (
                <div className="flex flex-col gap-4 my-4"><p className="text-center">{`Please wait...`}</p><RingLoader /></div>
              ) : (
                <>
                  <TextInput
                    className="input w-full"
                    containerClassName="w-full"
                    type="text"
                    name="phone"
                    required
                    value={phone}
                    description="Sign in with one time passcode."
                    disabled={submitLoading}
                    placeholder="Mobile Number"
                    onChange={({ target: { value } }) => {
                      setSendError( `` )
                      setSentOneTimePassword( false )

                      return setPhone( formatPhoneNumber( value ) )
                    }}
                    onBlur={() => { if ( !Validators.phone( phone ) ) setSendError( `Please enter a valid phone number` ) }}
                  />
                  <button
                    className="primary-button" onClick={handleOneTimePasswordSend} disabled={!Validators.phone( phone ) || !phone || submitLoading}
                  >
                    {`Send`}
                  </button>
                  {sendError && <div className="text-center text-error"><p>{sendError}</p></div>}
                  {
                    sentOneTimePassword && (
                      <div className="text-center text-secondary">
                        <p>{`A one-time password has been sent to your phone. Please enter the code below to login.`}</p>
                      </div>
                    )
                  }
                </>
              )
            }
          </div>
        ) : (
          <>
            {
              submitLoading ?
                <div className="flex flex-col my-4 gap-3"><p className="text-center">{`Please wait...`}</p><RingLoader /></div>
                : (
                  <div className="flex flex-col items-center justify-center gap-5 w-full">
                    <div className="relative w-full">
                      <TextInput
                        containerClassName="w-full"
                        className="input w-full"
                        type="text"
                        name="oneTime"
                        required
                        value={oneTime}
                        description="Sign in with one time passcode."
                        placeholder="One Time Passcode"
                        disabled={submitLoading || !sentOneTimePassword}
                        onChange={({ target: { value } }) => {
                          setSubmitError( `` )

                          return setOneTime( value )
                        }}
                      />
                      <button
                        className="absolute right-0 font-thin top-7"
                        onClick={() => {
                          setSentOneTimePassword( false )
                          handleOneTimePasswordSend()
                        }}
                      >
                        {`Resend`}
                      </button>
                    </div>
                    <button
                      className="primary-button" disabled={submitLoading || !sentOneTimePassword || !oneTime} onClick={handleOneTimePasswordSignIn}
                    >
                      {`Login`}
                    </button>

                    {submitError && !submitLoading && <div className="text-center text-error my-4"><p>{submitError}</p></div>}
                  </div>
                )
            }
          </>
        )
      }
    </div>
  )
}

export default OneTimePasswordForm